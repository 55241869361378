import React, { useState } from 'react';
import { View, Text } from 'react-native';
import Colors from '../../theme/colors';
import TouchableItem from '../TouchableItem';
import MinusSVG from '../svgs/MinusSVG';
import PlusSVG from '../svgs/PlusSVG';
import BodyA from '../bodyA/BodyA';
import SubheadingA from '../subheadingA/SubheadingA';
import {useTranslation} from 'react-i18next';

const TicketStatus = ({ ticket, outDated, comming, t }) => (
  <BodyA
    style={{
      fontFamily: 'Light',
      color: ticket.initialQuantity <= ticket?.soldQuantity ? Colors.red : Colors.darkGreen,
    }}>
    {ticket.initialQuantity <= ticket?.soldQuantity
      ? t('EventBPage.épuisé')
      : outDated
      ? t('EventBPage.expiré')
      : comming
      ? t('EventBPage.à venir')
      : t('EventBPage.disponible')}
  </BodyA>
);

const getTranslatedText = (translations, selectedLanguage) => {
  return translations?.[selectedLanguage] || translations?.fr;
};

const PriceDisplay = ({ 
    ticket,
    currentPrice,
    originalPrice,
    t,
    count,
    handleDecrease,
    handleIncrease,
    maxOrderTickets,
    filteredProduct,
    notAvailable
  }) => {
    const calculateFees = (basePrice) => {
      return (basePrice * (ticket.platformFee || 0)) / 100;
    };
    console.log('ticket', ticket);
    const fees = ticket.platformFee ? (ticket.platformFee)  : 0;
  
    
    return (
      <View style={styles.priceQuantityRow}>
        {/* Price and Fee */}
        <View style={styles.priceContainer}>
            {ticket.isFree ? (
              <Text style={styles.price}>{t('EventBPage.free')}</Text>
            ) : (
              <>
                <Text style={styles.price}>{`€${originalPrice.toFixed(2)}`}</Text>
                {fees > 0 && (
                  <Text style={styles.fee}>{` +€${fees.toFixed(2)} ${t('EventBPage.fees')}`}</Text>
                )}
              </>
            )}
        </View>

        {/* Quantity Controls */}
        {!notAvailable && (
            <View style={styles.quantityControls}>
                <TouchableItem onPress={() => count > 0 && handleDecrease(ticket)}>
                    {count > 0 && <MinusSVG style={styles.iconButton} />}
                </TouchableItem>
                
                <SubheadingA style={styles.quantityText}>{count}</SubheadingA>
                
                <TouchableItem onPress={() => handleIncrease(ticket, maxOrderTickets)}>
                    {(filteredProduct?.quantity >= maxOrderTickets) || 
                    (filteredProduct?.quantity === ticket?.maxQuantityPerOrder) ? (
                    <View style={styles.iconButton} />
                    ) : (
                    <PlusSVG style={styles.iconButton} />
                    )}
                </TouchableItem>
            </View>
        )}
      </View>
    );
  };

const TicketCard = ({
    ticket,
    index,
    outDated,
    comming,
    notAvailable,
    count,
    handleDecrease,
    handleIncrease,
    maxOrderTickets,
    filteredProduct,
    isMinimumPerOrderNotRespect,
    selectedLanguage,
    currentPrice,
    originalPrice,
  }) => {
    const { t } = useTranslation();
    const [showDescription, setShowDescription] = useState(false);
    
    const description = ticket?.products?.length > 0
      ? ticket.products.map((p) => p.product).join(', ')
      : getTranslatedText(ticket?.descriptiontranslations, selectedLanguage);
  
    return (
      <View style={styles.container} key={index}>
        <View style={styles.ticketContent}>
          {/* Header Section */}
          <View style={styles.ticketInfo}>
            <Text style={styles.ticketName}>{ticket?.name}</Text>
            <TicketStatus ticket={ticket} outDated={outDated} comming={comming} t={t} />
          </View>

          <PriceDisplay 
            ticket={ticket}
            currentPrice={currentPrice}
            originalPrice={originalPrice}
            t={t}
            count={count}
            handleDecrease={handleDecrease}
            handleIncrease={handleIncrease}
            maxOrderTickets={maxOrderTickets}
            filteredProduct={filteredProduct}
            notAvailable={notAvailable}
          />
        </View>

        {/* Description (shown when Read more is clicked) */}
        {showDescription && description && (
          <Text style={styles.description}>{description}</Text>
        )}

        {/* Read More Button */}
        {description && (
          <TouchableItem onPress={() => setShowDescription(!showDescription)}>
            <Text style={styles.readMore}>
              {showDescription ? (t('EventBPage.readLess')) : (t('EventBPage.readMore'))}
            </Text>
          </TouchableItem>
        )}
  
        {/* Minimum Order Warning */}
        {isMinimumPerOrderNotRespect && (
          <View style={styles.warningContainer}>
            <Text style={styles.warningText}>
              {t("EventBPage.Minimum par commande")}: {ticket.minQuantityPerOrder}
            </Text>
          </View>
        )}
      </View>
    );
  };

const styles = {
  container: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    padding: 16,
    marginBottom: 14,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  ticketContent: {
    flexDirection: 'column',
    gap: 12,
  },
  ticketInfo: {
    gap: 4,
  },
  ticketName: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.darkText,
  },
  description: {
    fontSize: 14,
    color: Colors.normalGray,
    marginTop: 12,
    marginBottom: 8,
  },
  priceQuantityRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    fontSize: 16,
    fontWeight: '500',
    color: Colors.darkText,
  },
  fee: {
    fontSize: 14,
    color: Colors.normalGray,
  },
  quantityControls: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  iconButton: {
    width: 33,
    height: 34,
  },
  quantityText: {
    color: '#1E2022',
    minWidth: 20,
    textAlign: 'center',
  },
  readMore: {
    color: '#0D6EFD',
    marginTop: 8,
    fontSize: 14,
  },
  warningContainer: {
    paddingVertical: 4,
  },
  warningText: {
    fontSize: 12,
    color: Colors.red,
  },
};

export default TicketCard;