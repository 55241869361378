import {StyleSheet} from 'react-native';
import Colors from '../../theme/colors';

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  contentContainerStyle: {
    flexGrow: 1, // Changed from flex: 1 to flexGrow: 1 for better scrolling
  },
  description: {
    fontFamily: 'Regular',
    fontSize: 14,
    lineHeight: 21,
    letterSpacing: 0.56,
    color: Colors.black,
    flexWrap: 'wrap',
  },
  htmlContainer: {
    flex: 1,
    marginHorizontal: -8, // Compensate for default RenderHtml padding
  },
  buttonText: {
    color: Colors.background,
    textAlign: 'center',
    fontFamily: 'SemiBold',
    fontSize: 16,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
  },
  textInput: {
    width: '100%',
    height: 46,
    borderRadius: 8,
    backgroundColor: Colors.white,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: '#D2D5DA',
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 1,
    marginVertical: 10,
  },
  coverImage: {
    width: '100%',
    height: 180,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: 25,
  },
  eventNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start', // Changed from center to flex-start
    flexWrap: 'wrap', // Added for better wrapping
    gap: 10, // Added for spacing between wrapped items
  },
  favoriteTouchableItem: {
    marginLeft: 'auto',
  },
  favorite: {
    width: 30,
    height: 27,
  },
  cardContent: {
    flexDirection: 'column',
  },
  contentContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  bodyRow: {
    flexDirection: 'row',
    alignItems: 'flex-start', // Changed from center to flex-start
    marginTop: 10,
    paddingRight: 20,
  },
  rowIcon: {
    width: 21,
    height: 21,
    marginRight: 10,
    marginTop: 3, // Added to align with first line of text
  },
  scrollView: {
    flex: 1, // Changed from width: '80%' to flex: 1
  },
  eventName: {
    color: Colors.darkGreen,
    fontFamily: 'SemiBold',
    fontSize: 24,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
    flex: 1, // Added to allow proper text wrapping
    flexWrap: 'wrap', // Added for text wrapping
  },
  info: {
    color: Colors.normalGray,
    fontFamily: 'Bold',
    fontSize: 16,
    letterSpacing: 0.64,
    textTransform: 'capitalize',
    marginTop: 10,
    flex: 1, // Added to allow text wrapping
    flexWrap: 'wrap', // Added for text wrapping
  },
  bodyA: {
    color: Colors.normalGray,
    flex: 1, // Added to allow text wrapping
    flexWrap: 'wrap', // Added for text wrapping
  },
  ownerDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10, // Added for better spacing
  },
  ownerImage: {
    height: 64,
    width: 64,
    borderRadius: 32,
  },
  ownerDetailsRow: {
    paddingVertical: 8,
  },
  ownerInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  ownerTextContainer: {
    justifyContent: 'center',
    flex: 1, // Added to allow text wrapping
  },
  button: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
    borderWidth: 1,
    height: 46,
    width: 123,
    borderColor: Colors.normalGray,
  },
  cartContainer: {
    marginVertical: 10,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
  },
  input: {
    backgroundColor: Colors.white,
    borderRadius: 15,
    shadowColor: 'black',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 2,
    paddingLeft: 20,
    paddingRight: 10,
    paddingVertical: 13,
  },
  adderContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '40%',
  },
  vibe: {
    backgroundColor: 'rgba(0,0,0, 0.0)',
    borderWidth: 1,
    height: 46,
    borderColor: Colors.normalGray,
    borderRadius: 20,
    paddingHorizontal: 16,
    marginRight: 16,
  },
  total: {
    marginVertical: 10,
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.lightGray,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  restrictionSelector: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 8,
    backgroundColor: Colors.lightGray,
  },
  selectedRestriction: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap', // Added for text wrapping
  },
  resetButton: {
    backgroundColor: Colors.red,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  selectButton: {
    backgroundColor: Colors.blue,
    padding: 10,
  },
  modalContent: {
    padding: 20,
  },
  modalTitle: {
    marginBottom: 10,
  },
  modalSubtitle: {
    marginBottom: 20,
  },
  emailInput: {
    borderWidth: 1,
    borderColor: Colors.gray,
    borderRadius: 4,
    padding: 10,
    marginBottom: 20,
  },
  restrictionButton: {
    marginBottom: 10,
    backgroundColor: Colors.blue,
    padding: 10,
  },
});

export default styles;