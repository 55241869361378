export const addProduct = (cartIndex) => ({
  type: 'ADD_PRODUCT',
  payload: cartIndex,
});
export const removeProductByID = (cartIndex) => ({
  type: 'REMOVE_PRODUCT_BY_ID',
  payload: cartIndex,
});
export const addCommandeGroupe = (cartIndex) => ({
  type: 'ADD_COMMANDE_GROUPE',
  payload: cartIndex,
});
export const removeCommandeGroupe = (cartIndex) => ({
  type: 'REMOVE_COMMANDE_GROUPE',
  payload: cartIndex,
});

export const addPromoCode = (cartIndex) => ({
  type: 'ADD_PROMOCODE',
  payload: cartIndex,
});

export const remPromoCode = (cartIndex) => ({
  type: 'REM_PROMOCODE',
  payload: cartIndex,
});


export const emptyCart = (cartIndex) => (dispatch) => {
  // First clear the cart
  dispatch({
    type: 'EMPTY_CART',
    payload: cartIndex,
  });
  
  // Then clear access restrictions
  dispatch({
    type: 'CLEAR_ACCESS_RESTRICTION'
  });
};

export const setStore = (store) => ({
  type: 'SET_STORE',
  payload: store,
});

export const setEvent = (store) => ({
  type: 'SET_EVENT',
  payload: store,
});

export const setReferral = (store) => ({
  type: 'SET_REFERRAL',
  payload: store,
});

export const setPickMode = (cartIndex) => ({
  type: 'SET_PICKMODE',
  payload: cartIndex,
});

export const setTierMode = (tierIndex) => ({
  type: 'SET_TIERMODE',
  payload: tierIndex,
});

export const setLastId = (lastIndex) => ({
  type: 'SET_LASTID',
  payload: lastIndex,
});

export const syncCart = (newproducts) => ({
  type: 'SYNC_CART',
  payload: newproducts,
});


// Update setAccessRestriction to handle cart clearing
export const setAccessRestriction = (restrictionInfo) => (dispatch, getState) => {
  const currentRestriction = getState().cart?.accessRestriction;
  console.log('setAccessRestriction', currentRestriction, restrictionInfo);
  
  // Clear mismatched products before setting new restriction
  if (currentRestriction?.verifiedEmail !== restrictionInfo?.verifiedEmail) {
    dispatch(clearMismatchedProducts(restrictionInfo));
  }
  
  dispatch({
    type: 'SET_ACCESS_RESTRICTION',
    payload: restrictionInfo
  });
};

// Add action for clearing access restriction
export const clearAccessRestriction = () => ({
  type: 'CLEAR_ACCESS_RESTRICTION',
});

export const clearMismatchedProducts = (newAccessRestriction) => ({
  type: 'CLEAR_MISMATCHED_PRODUCTS',
  payload: newAccessRestriction
});

export const cleanupCartAndRestrictions = () => ({
  type: 'CLEANUP_CART_AND_RESTRICTIONS'
});