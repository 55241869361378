/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

// import dependencies
import 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import {LogBox} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {db} from './src/services/firebase';
import {LanguageProvider} from './src/contexts/languageEventsContext.js';
import ServiceWorkerUpdateHandler from './ServiceWorkerUpdateHandler';
// CUSTOM FONTS
import useCustomFonts from './expo-fonts';
import AsyncStorage from "@react-native-async-storage/async-storage";

import AppLoading from 'expo-app-loading';
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';
//enableScreens();

// TODO: Remove when fixed
LogBox.ignoreAllLogs();
/*YellowBox.ignoreWarnings([
  'VirtualizedLists should never be nested',
  'Warning: componentWillReceiveProps has been renamed, and is not recommended',
]);*/

// import MainNavigatorA or MainNavigatorB to preview design differnces
import RoutesA from './src/navigation/RoutesA';
import * as Routes from './src/navigation/RoutesA';
import {
  SafeAreaView,
  StatusBar,
  View,
  Image,
  AppState,
  AppRegistry,
  I18nManager,
} from 'react-native';
import {AuthContext} from './src/contexts/context';
import {
  loggingOut,
  registration,
  resetPassword,
  signInFirebase,
  signInAnonymouslyAsync,
} from './src/services/firebase';
import {Provider} from 'react-redux';
//import cartReducer from './src/reducers/cartreducer';
import authReducer from './src/reducers/authreducer';
import ErrorBoundary from './src/components/errors/ErrorBoundary';
import {Heading6} from './src/components/text/CustomText';

import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './src/reducers/cartreducer';
import firebase from 'firebase/compat/app';
import {FavoritesEventsContextProvider} from './src/contexts/favoritesEventsContext';
import i18n from './src/config/i18n/i18n';
import { version } from './package.json';

//const store = createStore(cartReducer);

const errorHandler = (error, stackTrace) => {
  /* Log the error to an error reporting service */
  console.log('error props  ===> ', error);
  // console.log(error);
  //console.log(stackTrace);
  db.collection('errors')
    .add({
      //errorInfo: errorInfo,
      stacktrace: stackTrace,
      error: error.message,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    })
    .then((docRef) => {
      console.log('Document written with ID: ', docRef.id);
    })
    .catch((error) => {
      console.error('Error adding document: ', error);
    });
};
const CustomFallback = (props) => (
  <SafeAreaView style={styles.screenContainer}>
    <StatusBar
      backgroundColor={Colors.statusBarColor}
      barStyle="dark-content"
    />

    <View style={styles.slide}>
      <Image source={slide1Img} style={styles.slideImg} />
      <Heading6 style={styles.title}>Quelque chose s'est mal passé.</Heading6>
      <View style={styles.bottomButtonContainer}>
        <Button
          onPress={props.resetError}
          title="Passer une nouvelle commande"
        />
      </View>
    </View>
  </SafeAreaView>
);

// APP

const App = (props) => {
  useCustomFonts();

  const initialLoginState = {
    isLoading: false,
    userName: null,
    userToken: null,
  };

  const clearCacheOnUpdate = async () => {
    const appVersion = version;
    const cachedVersion = await AsyncStorage.getItem("appVersion");
    console.log("version", appVersion);
 
    if (cachedVersion !== appVersion) {
        await AsyncStorage.clear();
        await AsyncStorage.setItem("appVersion", appVersion);
    }
 };

 useEffect(() => {
  clearCacheOnUpdate();
}, []);

  useEffect(async () => {
    console.log('starting app');
    let result = await signInAnonymouslyAsync();
    //console.log(result);
  });

  const [authState, dispatch] = React.useReducer(
    authReducer,
    initialLoginState,
  );

  const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    return (
      <AuthContext.Provider
        value={{
          user,
          setUser,
          isLoading,
          setIsLoading,
          logIn: async (userName, password) => {
            console.log('begin logIn!!!!!!!!!!');
            setIsLoading(true);
            try {
              let result = await signInFirebase(userName, password);
              console.log('result.uid of signInFirebase ===>', result.uid);
              dispatch({type: 'LOGIN', id: result.uid});
              console.log('finished logIn!!!!!!!!!!');
              // setIsLoading(false);
            } catch (e) {
              console.log(e);
              // setIsLoading(false);
            }
          },
          signInAnonymously: async () => {
            try {
              let result = await signInAnonymouslyAsync();
              dispatch({type: 'LOGIN'});
            } catch (e) {
              console.log(e);
            }
          },
          signUp: async (email, password, firstname, lastname) => {
            console.log('begin signUp!!!!!!!!!!');
            setIsLoading(true);
            try {
              dispatch({type: 'REGISTER_LAUNCH'});
              let result = await registration(
                email,
                password,
                lastname,
                firstname,
              );

              dispatch({type: 'LOGIN', id: result.uid});
              console.log('finished signUp!!!!!!!!!!');
              // setIsLoading(false);
            } catch (e) {
              console.log(e);
              setIsLoading(false);
            }
          },
          signOut: async () => {
            try {
              console.log('loggin out');
              loggingOut();
              dispatch({type: 'LOGOUT'});
            } catch (e) {
              console.error(e);
            }
          },
          passwordReset: async (email) => {
            try {
              console.log('loggin out');
              resetPassword(email);
              dispatch({type: 'LOGOUT'});
            } catch (e) {
              console.error(e);
            }
          },
        }}>
        {children}
      </AuthContext.Provider>
    );
  };

  if (authState.isLoading) {
    console.log('application is loading');
    return (
      <AppLoading onFinish={() => console.log('')} onError={console.warn} />
    );
  }

  return (
    <SafeAreaProvider>
      <ErrorBoundary FallbackComponent={CustomFallback} onError={errorHandler}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <FavoritesEventsContextProvider>
                <LanguageProvider>
                  <RoutesA />
                </LanguageProvider>
              </FavoritesEventsContextProvider>
              {/* <ServiceWorkerUpdateHandler /> */}
            </AuthProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
};

export default App;
serviceWorkerRegistration.register();
