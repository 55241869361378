import React, {useState, useMemo} from 'react';
import {View, TextInput, StyleSheet} from 'react-native';
import {useTranslation} from 'react-i18next';
import ModalA from '../modalA/ModalA';
import TitleA from '../titleA/TitleA';
import SubheadingA from '../subheadingA/SubheadingA';
import ButtonA from '../buttonA/ButtonA';
import BodyA from '../bodyA/BodyA';
import Colors from '../../theme/colors';
import DropDownInput from '../dropDownInput/DropDownInput';

const AccessRestrictionModal = ({
  visible,
  onClose,
  accessRestrictions,
  onValidateEmail,
  currentRestriction
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [selectedRestrictionName, setSelectedRestrictionName] = useState('');

  // Create lookup maps for restrictions
  const restrictionsByName = useMemo(() => {
    const names = {};
    accessRestrictions.forEach(restriction => {
      names[restriction.name] = restriction;
    });
    return names;
  }, [accessRestrictions]);

  //console.log("restrictionsByName", restrictionsByName)
  //console.log("accessRestrictions", accessRestrictions)

// Create array of restriction names for dropdown
  const restrictionNames = useMemo(
    () => accessRestrictions.map((r) => r.name),
    [accessRestrictions],
  );

  const selectedRestriction = selectedRestrictionName
    ? restrictionsByName[selectedRestrictionName]
    : null;

  const validateEmail = (email) => {
    return email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
  };

  const handleSubmit = () => {
    // if (!selectedRestriction) {
    //   setError(t('EventBPage.Please select an access type'));
    //   return;
    // }

    if (!validateEmail(email)) {
      setError(t('EventBPage.Please enter a valid email'));
      return;
    }

    // Check if email domain matches restriction domain
    const emailDomain = email.split('@')[1];
    let filterdSelectedRestriction = accessRestrictions.filter(accr => 
      accr.condition.domains.some(domain => domain.toLowerCase() === emailDomain.toLocaleLowerCase())
    );
    if (filterdSelectedRestriction.length === 0) {
      setError(t('EventBPage.Invalid Email Domain'));
      return;
    }   
    onValidateEmail(email, filterdSelectedRestriction[0]);
    setEmail('');
    setError('');
    setSelectedRestrictionName('');
  };

  const handleRestrictionChange = (name) => {
    setSelectedRestrictionName(name);
    setError('');
  };

  return (
    <ModalA
      visible={visible}
      onDismiss={onClose}
    >
      <View style={styles.modalContent}>
        <TitleA style={styles.modalTitle}>
          {currentRestriction
            ? t('EventBPage.Change Access Verification')
            : t('EventBPage.Verify Access')}
        </TitleA>
        
        {currentRestriction && (
          <SubheadingA style={styles.currentAccess}>
            {t('EventBPage.Current Access')}: {currentRestriction.name}
          </SubheadingA>
        )}

        <SubheadingA style={styles.modalSubtitle}>
          {t(
            'EventBPage.Please verify your email to access restricted tickets',
          )}
        </SubheadingA>

        <View style={styles.dropdownContainer}>
          {/* <SubheadingA style={styles.label}>
            {t('EventBPage.Access Type')}
          </SubheadingA>
          <DropDownInput
            placeholder={t('EventBPage.Select Access Type')}
            dataArray={restrictionNames}
            value={selectedRestrictionName}
            onChangeText={handleRestrictionChange}
            style={[styles.dropdown, error && !selectedRestriction && styles.dropdownError]}
          /> */}
          {/* {selectedRestriction && (
            <View style={styles.domainsContainer}>
              {selectedRestriction.condition.domains.map(domain => (
                <BodyA key={domain} style={styles.domainText}>
                  @{domain}
                </BodyA>
              ))}
            </View>
          )} */}
        </View>

        <TextInput
          value={email}
          onChangeText={(text) => {
            setEmail(text);
            setError('');
          }}
          placeholder={t('EventBPage.Enter your email')}
          keyboardType="email-address"
          autoCapitalize="none"
          style={[styles.emailInput, error ? styles.emailInputError : null]}
        />

        {error ? <BodyA style={styles.errorText}>{error}</BodyA> : null}

        <ButtonA
          onPress={handleSubmit}
          style={styles.submitButton}
          color={Colors.white}>
          {t('EventBPage.Verify')}
        </ButtonA>

        <ButtonA
          onPress={onClose}
          style={styles.cancelButton}
          color={Colors.darkGray}>
          {t('EventBPage.Cancel')}
        </ButtonA>
      </View>
    </ModalA>
  );
};

const styles = StyleSheet.create({
  modalContent: {
    padding: 20,
  },
  modalTitle: {
    marginBottom: 16,
    textAlign: 'center',
  },
  currentAccess: {
    marginBottom: 16,
    color: Colors.darkGray,
    textAlign: 'center',
  },
  modalSubtitle: {
    marginBottom: 20,
    textAlign: 'center',
  },
  dropdownContainer: {
    marginBottom: 16,
  },
  label: {
    marginBottom: 8,
    fontSize: 14,
  },
  dropdown: {
    borderWidth: 1,
    borderColor: Colors.gray,
    borderRadius: 4,
    marginBottom: 4,
  },
  dropdownError: {
    borderColor: Colors.red,
  },
  emailInput: {
    borderWidth: 1,
    borderColor: Colors.gray,
    borderRadius: 4,
    padding: 12,
    marginBottom: 8,
    fontSize: 16,
  },
  emailInputError: {
    borderColor: Colors.red,
  },
  errorText: {
    color: Colors.red,
    marginBottom: 16,
    fontSize: 14,
  },
  domainsContainer: {
    marginTop: 4,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
  domainText: {
    fontSize: 12,
    color: Colors.darkGray,
  },
  submitButton: {
    backgroundColor: Colors.blue,
    padding: 12,
    width: '100%',
    marginBottom: 12,
  },
  cancelButton: {
    backgroundColor: Colors.lightGray,
  },
});

export default AccessRestrictionModal;
